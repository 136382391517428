class CharacterGlow {
  static gsap;

  #elements = {};

  #timelines = {};

  constructor({
    zeus = '.character-glow-zeus',
    trait = '.character-glow-trait',
  } = {}) {
    this.#elements = { zeus, trait };
    const gsap = CharacterGlow.gsap ?? window.gsap;

    Object.keys(this.#elements).forEach((key) => {
      this.#timelines[key] = gsap.timeline({ paused: true });
    });
  }

  static registerGSAP(gsap) {
    CharacterGlow.gsap = gsap;
  }

  #configureAnimation(element, shadowColorFrom, shadowColorTo, shadowDisplacementFrom, shadowDisplacementTo) {
    const tl = this.#timelines[element];
    const target = this.#elements[element];

    tl.fromTo(target, {
      '--shadow-color': shadowColorFrom,
    }, {
      duration: 2,
      '--shadow-color': shadowColorTo,
      ease: 'sine.inOut',
      repeat: -1,
      yoyo: true,
    }).fromTo(target, {
      '--shadow-displacement': shadowDisplacementFrom,
    }, {
      duration: 1.5,
      '--shadow-displacement': shadowDisplacementTo,
      ease: 'power1.inOut',
      repeat: -1,
      yoyo: true,
    }).play();
  }

  animation() {
    this.#configureAnimation('zeus', '160', '240', '0', '3');
    this.#configureAnimation('trait', '300', '385', '0', '3');
  }

  init() {
    this.animation();
  }
}

export default CharacterGlow;
