import Iconly from 'iconly';
import gsap from 'gsap';
import VideoController from './utils/VideoController';
import CharacterGlow from './utils/CharacterGlow';
import LightningSimulator from './utils/LightningSimulator';
import ModalManager from './utils/ModalManager';

CharacterGlow.registerGSAP(gsap);

// Настройка иконок
const svgSet = new Iconly({
  file: './sprite.svg',
  version: '28.0',
  debug: true,
});

// Модальное окно для регистрации
const modalManager = new ModalManager();
const closeButton = document.querySelector('.modal-content__close');
const backdrop = document.querySelector('.modal__backdrop');

//
const videoController = new VideoController({
  element: '.slot-cards',
});

videoController.init();

//
const characterGlow = new CharacterGlow({
  zeus: '.character-glow-zeus',
  trait: '.character-glow-trait',
});

new LightningSimulator('lightning');// TODO исправить инициализацию

// Инициализация приложения
const app = async () => {







  characterGlow.init();
  setTimeout(() => modalManager.openModal(), 2250);
  closeButton.addEventListener('click', () => modalManager.closeModal());
  backdrop.addEventListener('click', () => modalManager.closeModal());
  await svgSet.init();
};

document.addEventListener('DOMContentLoaded', app);
