class LightningSimulator {
  #canvas;

  #ctx;

  #lightnings = [];

  #previousTimestamp = 0;

  #MAX_POINTS = 120;

  #MAX_X_DISTANCE = 12;

  #MAX_Y_DISTANCE = 22;

  #MAX_WIDTH = 3;

  #FADE_INCREMENT = 0.013;

  #LIGHTNING_CHANCE = 0.03;

  #BRANCH_CHANCE = 0.01;

  #FLICKER_CHANCE = 0.023;

  #TIME_BETWEEN_LIGHTNING = 500;

  #MIN_NEW_LINES = 4;

  #MAX_NEW_LINES = 11;

  #stageWidth;

  #stageHeight;

  constructor(canvasId) {
    this.#canvas = document.getElementById(canvasId);
    this.#ctx = this.#canvas.getContext('2d');
    this.#updateCanvasSize();

    window.addEventListener('resize', this.#updateCanvasSize.bind(this));

    requestAnimationFrame(this.#loop.bind(this));
  }

  #updateCanvasSize = () => {
    this.#stageWidth = window.innerWidth;
    this.#stageHeight = window.innerHeight;
    this.#canvas.width = this.#stageWidth;
    this.#canvas.height = this.#stageHeight;
  };

  #random = (max = 1, unsigned = false) => (
    unsigned ? ((Math.random() - 0.5) * 2) * max : Math.random() * max
  );

  #loop = () => {
    const now = Date.now();

    if (this.#random() < this.#LIGHTNING_CHANCE && now - this.#previousTimestamp > this.#TIME_BETWEEN_LIGHTNING) {
      this.#createLightning();
      this.#previousTimestamp = now;
    }

    this.#ctx.clearRect(0, 0, this.#stageWidth, this.#stageHeight);
    this.#lightnings = this.#lightnings.filter((lightning) => lightning.alpha > 0);

    this.#lightnings.forEach((lightning) => this.#animateAndRenderLightning(lightning));

    requestAnimationFrame(this.#loop.bind(this));
  };

  #createLightning = () => {
    const lightning = {
      paths: [{ x: this.#random(this.#stageWidth), y: 40 + this.#random(100) }],
      red: 255,
      green: 255,
      blue: 255,
      alpha: 1,
      width: this.#random(this.#MAX_WIDTH) + 1,
      hasEnded: false,
      isBranch: false,
      xDeviation: 1,
      flickerCount: 0,
    };

    this.#lightnings.push(lightning);
  };

  #animateAndRenderLightning = (lightning) => {
    this.#animateLightning(lightning);
    this.#renderLightning(lightning);
  };

  #animateLightning = (lightning) => {
    if (lightning.hasEnded) {
      lightning.alpha -= this.#FADE_INCREMENT;

      if (lightning.alpha < 0.5) lightning.green -= 4.5;

      return;
    }

    let lastPoint = lightning.paths[lightning.paths.length - 1];

    const newLines = this.#MIN_NEW_LINES + Math.floor(this.#random(this.#MAX_NEW_LINES - this.#MIN_NEW_LINES + 1));

    for (let i = 0; i < newLines; i++) {
      const newX = lastPoint.x + this.#random(this.#MAX_X_DISTANCE, true) * lightning.xDeviation;
      const newY = lastPoint.y + this.#random(this.#MAX_Y_DISTANCE) + 2;

      lightning.paths.push({ x: newX, y: newY });
      lastPoint = { x: newX, y: newY };

      if (this.#random() < this.#BRANCH_CHANCE && !lightning.isBranch) {
        this.#createBranch(lightning, newX, newY);
      }
    }

    lightning.hasEnded = this.#checkIfEnded(lightning, lastPoint);

    if (!lightning.isBranch && this.#random() < this.#FLICKER_CHANCE && lightning.flickerCount < 2 && lightning.alpha > 0.3) {
      lightning.alpha = 1;
      lightning.green = 240;
      lightning.flickerCount++;
    }
  };

  #createBranch = (parent, x, y) => {
    const branch = {
      ...parent,
      paths: [{ x, y }],
      isBranch: true,
      alpha: parent.alpha,
      width: Math.max(1, parent.width - 1),
      flickerCount: 0,
      xDeviation: 1.3,
      branchDirection: (Math.random() - 0.5) * 2,
    };

    this.#lightnings.push(branch);
  };

  #checkIfEnded = (lightning, lastPoint) => {
    const isLongEnough = lightning.paths.length > 20;

    return (lastPoint.y / this.#stageHeight > 0.8
      || lightning.paths.length > this.#MAX_POINTS
      || (lightning.isBranch && lightning.paths.length > 10)
      || (!lightning.isBranch && this.#random() > 0.95)) && isLongEnough;
  };

  #renderLightning = (lightning) => {
    this.#ctx.beginPath();
    this.#ctx.strokeStyle = `rgba(${lightning.red}, ${lightning.green}, ${lightning.blue}, ${lightning.alpha})`;
    this.#ctx.lineWidth = lightning.width;

    lightning.paths.forEach((path, index) => {
      if (index === 0) {
        this.#ctx.moveTo(path.x, path.y);
      } else {
        this.#ctx.lineTo(path.x, path.y);
      }
    });

    this.#ctx.stroke();
  };
}

export default LightningSimulator;
