import MediaTrigger from 'media-trigger';

class VideoController {
  #element = null;

  constructor({ element = null } = {}) {
    if (typeof element === 'string') {
      this.#element = document.querySelector(element);
    } else if (element instanceof HTMLElement) {
      this.#element = element;
    } else {
      this.#element = null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  #addEventListener({ video, eventType, action }) {
    video.addEventListener(eventType, () => action(video));
  }

  // eslint-disable-next-line class-methods-use-this
  #createTrigger({
    media,
    video,
    entry,
    exit,
  }) {
    return new MediaTrigger({
      media,
      entry: () => entry(video),
      exit: () => exit(video),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  #toggleDisplay = (video, action) => {
    video.classList.add('video--hidden');

    setTimeout(() => {
      action();

      video.classList.remove('video--hidden');
    }, 150);
  };

  #toggleAutoplay = (video, action) => {
    this.#toggleDisplay(video, action);
  };

  #autoplay = (video) => {
    this.#createTrigger({
      media: '(min-width: 1200px) and (any-pointer: fine)',
      video,
      entry: () => this.#toggleAutoplay(video, () => video.removeAttribute('autoplay')),
      exit: () => this.#toggleAutoplay(video, () => video.setAttribute('autoplay', '')),
    });
  };

  init = () => {
    if (!this.#element) {
      return;
    }

    this.#element.querySelectorAll('video').forEach((video) => {
      this.#addEventListener({ video, eventType: 'mouseenter', action: (v) => v.play() });
      this.#addEventListener({ video, eventType: 'mouseleave', action: (v) => v.pause() });

      this.#autoplay(video);
    });
  };
}

export default VideoController;
